var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stock-management-card", style: { height: "95px" } },
    [
      _c("div", { staticClass: "fw500 flex bet a-center" }, [
        _c("div", {
          style: { "margin-bottom": "10px", "margin-right": "25px" },
          domProps: {
            textContent: _vm._s(_vm.getItemNameById(_vm.item.item_id)),
          },
        }),
      ]),
      _c(
        "div",
        {
          staticClass: "stock-management-card__main",
          style: {
            display: "flex",
            "justify-content": "space-between",
            width: "100%",
            "border-bottom": "none",
          },
          on: { click: _vm.data.click },
        },
        [
          _c("div", { staticClass: "item-card__main_name" }, [
            _c("div", {
              staticClass: "grey",
              domProps: { textContent: _vm._s(_vm.$t("current stock")) },
            }),
            _c("div", {
              domProps: { textContent: _vm._s(_vm.item.current_stock) },
            }),
          ]),
          _c("div", { staticClass: "item-card__main_name" }, [
            _c("div", {
              staticClass: "grey",
              domProps: { textContent: _vm._s(_vm.$t("daily stock")) },
            }),
            _c("div", {
              domProps: { textContent: _vm._s(_vm.item.daily_stock) },
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }